import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import * as React from "react";
import "../../styles.less";
import { Link } from "office-ui-fabric-react";
import { TranslationService } from "../../services/TranslationService";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import AppHeaderComponent from "./header/AppHeaderComponent";
import { HashRouter, Route, Switch } from "react-router-dom";
import { RedirectComponent } from "./RedirectComponent";
import SendAndFileComponent from "../sendAndFile/SendAndFileComponent";
import { Authenticator } from "../../services/Authenticator";
import { NewformaApiClient } from "../../services/NewformaApi/NewformaApiClient";
import { AnalyticsManager } from "../../services/AnalyticsManager";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import LoginComponent from "../login/LoginComponent";
import { createHashHistory } from "history";
import { MailboxItem, OfficeWrapper } from "../../services/OfficeWrapper";
import { OfficeNotificationService } from "../../services/officeUi/OfficeNotificationService";
import { ConfigurationService } from "../../services/ConfigurationService";
import { UnauthenticatedNewformaApiClient } from "../../services/UnauthenticatedNewformaApiClient";
import ActionItemComponent from "../actionItem/ActionItemComponent";
import { ActionItemApiService } from "../../services/NewformaApi/ActionItemApiService";
import { StorageWrapper } from "../../services/StorageWrapper";
import { SessionStorageKeys } from "../../models/StorageKeys";
import { ConfigurationsApiService } from "../../services/NewformaApi/ConfigurationsApiService";
import { WindowWrapper } from "../../services/WindowWrapper";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import { CustomerSupportEmailService } from "../../services/CustomerSupportEmailService";
import { Logger } from "../../services/Logger";
import { IProjectsService } from "../../services/NewformaApi/IProjectsService";
import { SendAndFileHelpers } from "../../helpers/SendAndFile/SendAndFileHelpers";
import SubmittalComponent from "../submittal/SubmittalComponent";
import { DateHelpers } from "../../helpers/DateHelpers";
import { SubmittalsApiService } from "../../services/NewformaApi/SubmittalsApiService";
import { EulaApiService } from "../../services/NewformaApi/EulaApiService";
import { Eula } from "../../models/EulaModels";
import EulaComponent from "../eula/EulaComponent";
import RfiComponent from "../rfi/RfiComponent";
import { RfiApiService } from "../../services/NewformaApi/RfiApiService";
import { getTheme, loadTheme, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { AppPage } from "../shared/navigationHeader/NavigationHeaderComponent";
import SubmittalReviewResponseComponent from "../submittal/submittalReviewResponse/SubmittalReviewResponseComponent";
import RfiReviewResponseComponent from "../rfi/rfiReviewResponse/RfiReviewResponseComponent";
import FileMultipleEmailComponent from "../fileMultipleEmail/FileMultipleEmailComponent";
import NavigationHeaderComponent from "../shared/navigationHeader/NavigationHeaderComponent";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { MsGraphApiService } from "../../services/MsGraphApiService";
import { NrnServiceWrapper } from "../../services/NrnServiceWrapper";
import { AuthApiService } from "../../services/NewformaApi/AuthApiService";
import { TeachingBubblesTypes } from "../../models/TeachingBubblesTypes";
import { ThemeProvider } from "@material-ui/styles";
import { customThemes, darkTheme, lightTheme } from "../../services/ThemeService";
import { InvalidateCacheService } from "../../services/NewformaApi/InvalidateCacheService";
import { Messenger } from "../../services/Messenger";
import { FileTransferApiService } from "../../services/NewformaApi/FileTransferApiService";
import { OutlookApiService } from "../../services/OutlookApiService";
import { getClassForToast } from "../../helpers/ToasterHelpers";

export interface AppProps extends LocalizeContextProps {
    officeRoamingSettings: OfficeRoamingSettings;
    authenticator: Authenticator;
    apiClient: NewformaApiClient;
    unauthenticatedApiClient: UnauthenticatedNewformaApiClient;
    analyticsManager: AnalyticsManager;
    smartFilingManager: SmartFilingManager;
    officeWrapper: OfficeWrapper;
    officeNotificationService: OfficeNotificationService;
    configService: ConfigurationService;
    actionItemApiService: ActionItemApiService;
    storageWrapper: StorageWrapper;
    configurationsApiService: ConfigurationsApiService;
    windowWrapper: WindowWrapper;
    formValidationHelpers: FormValidationHelpers;
    translationService: TranslationService;
    customerSupportEmailService: CustomerSupportEmailService;
    logger: Logger;
    projectsService: IProjectsService;
    sendAndFileHelpers: SendAndFileHelpers;
    dateHelpers: DateHelpers;
    submittalsApiService: SubmittalsApiService;
    eulaApiService: EulaApiService;
    rfiApiService: RfiApiService;
    emailApiService: EmailApiService;
    msGraphApiService: MsGraphApiService;
    nrnServiceWrapper: NrnServiceWrapper;
    authApiService: AuthApiService;
    invalidateCacheService: InvalidateCacheService;
    messenger: Messenger;
    fileTransferApiService: FileTransferApiService;
    outlookApiService: OutlookApiService;
}

export interface AppState {
    isLoggedIn: boolean;
    deleteEmailAfterFilingSetting: boolean;
    fileEmailConversationSetting: boolean;
    showAllGlobalProjectsSetting: boolean;
    showEula: boolean;
    isEulaUpdate: boolean;
    toasts: { message: string; type: MessageBarType; id: number }[];
    onEulaUserResponse: ((eula: Eula | undefined) => void) | undefined;
    toastMessage: string | null;
    toastType: MessageBarType | null;
    navigationPage: AppPage;
    isEulaSupported: boolean;
    isActionItemsSupported: boolean;
    isSubmittalsSupported: boolean;
    isRfiSupported: boolean;
    isFileTransferAndEditorSupported: boolean;
    isFilePathInAttachmentSupported: boolean;
    isForwardSubmittalSupported: boolean;
    isForwardRfiSupported: boolean;
    mailboxItem: MailboxItem | null;
    theme: string;
    entryPointUrl: string;
    locationPath: string;
    shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache: boolean;
    showFilesTransferTeachingBubble: boolean;
    showForwardRfiTeachingBubble: boolean;
    showForwardSubmittalTeachingBubble: boolean;
    shouldShowFileTransferButton: boolean;
    hasMyProjects: boolean;
    shouldShowFileTransferPanel: boolean;
}

Office.initialize = () => {
    // NOTE: Keep - required. This also fires before .async()
};
// tslint:disable-next-line
(async () => {
    await Office.onReady();
})();

class AppComponent extends React.Component<AppProps, AppState> {
    private history = createHashHistory();
    private readonly loginPath = "/login";
    private readonly infoToastKey = "newforma-info-message";
    private container: HTMLElement | null = null;
    constructor(props: AppProps, context: AppState) {
        super(props, context);
        this.props.authenticator.onEulaRequired = this.onEulaRequired.bind(this);
        this.props.authenticator.onAuthenticationError = this.onAuthenticationError.bind(this);
        props.officeWrapper.addHandlerAsync(Office.EventType.ItemChanged, this.itemChanged.bind(this));
        props.translationService.getTranslations(this.props);
        this.state = {
            isLoggedIn: this.props.authenticator.isUserLoggedIn(),
            deleteEmailAfterFilingSetting: this.props.officeRoamingSettings.getDeleteEmailAfterFiling(),
            fileEmailConversationSetting: this.props.officeRoamingSettings.getFileEmailConversation(),
            showAllGlobalProjectsSetting: !this.props.officeRoamingSettings.getFileEmailConversation(),
            showEula: false,
            isEulaUpdate: false,
            toasts: [],
            onEulaUserResponse: undefined,
            toastMessage: null,
            toastType: null,
            navigationPage: AppPage.FileMultipleEmail,
            isEulaSupported: false,
            isActionItemsSupported: false,
            isSubmittalsSupported: false,
            isRfiSupported: false,
            isFileTransferAndEditorSupported: false,
            isFilePathInAttachmentSupported: false,
            isForwardSubmittalSupported: false,
            isForwardRfiSupported: false,
            mailboxItem: this.props.officeWrapper.currentContextItem,
            theme: localStorage.getItem("theme") ?? "default",
            entryPointUrl: this.props.storageWrapper.loadSessionStorage(SessionStorageKeys.urlLocation) || "",
            locationPath: this.history.location.pathname,
            shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache: false,
            showFilesTransferTeachingBubble: false,
            showForwardRfiTeachingBubble: false,
            showForwardSubmittalTeachingBubble: false,
            shouldShowFileTransferButton: true,
            hasMyProjects: true,
            shouldShowFileTransferPanel: false,
        };
    }

    async componentDidMount(): Promise<void> {
        if (this.state.locationPath !== this.loginPath) {
            this.props.storageWrapper.saveSessionStorage(SessionStorageKeys.urlLocation, this.state.locationPath);
        }
        if (this.state.locationPath === "/fileemail" || this.state.entryPointUrl === "/fileemail") {
            this.props.storageWrapper.saveSessionStorage(SessionStorageKeys.urlLocation, "/filemultipleemail");
            this.setState({ locationPath: "/filemultipleemail", entryPointUrl: "/filemultipleemail" });
        }
        if (this.state.locationPath === "/sendandfile") {
            this.setState({ entryPointUrl: "/sendandfile" });
        }
        this.props.logger.info(`Add-in loaded. Entry Point: ${this.state.entryPointUrl}`);
        this.state.isLoggedIn
            ? await this.props.configurationsApiService.getAuthenticatedConfigurations()
            : await this.props.configurationsApiService.getUnauthenticatedConfigurations();
        this.getFeatureSupport();
        const shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache = this.props.configurationsApiService.shouldTeachingBubbleBeShown(
            TeachingBubblesTypes.fileMultipleEmail
        );
        const showFilesTransferTeachingBubble = this.props.configurationsApiService.shouldTeachingBubbleBeShown(
            TeachingBubblesTypes.filesTransfer
        );
        const showForwardRfiTeachingBubble = this.props.configurationsApiService.shouldTeachingBubbleBeShown(
            TeachingBubblesTypes.forwardReviewRfi
        );
        const showForwardSubmittalTeachingBubble = this.props.configurationsApiService.shouldTeachingBubbleBeShown(
            TeachingBubblesTypes.forwardReviewSubmittal
        );
        this.setState({
            shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache,
            showFilesTransferTeachingBubble,
            showForwardRfiTeachingBubble,
            showForwardSubmittalTeachingBubble,
        });
        this.getDropdownBoxShadow();
        this.container = document.getElementById("container");
        window.addEventListener("logoutEvent", this.handleLogoutEvent);
    }
    async componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<AppState>) {
        if (prevState.theme !== this.state.theme) {
            this.getDropdownBoxShadow();
        }
    }

    private addToast = (message: string, type: MessageBarType) => {
        const id = Date.now();
        const newToast = { message, type, id };
        this.setState((prevState) => ({
            toasts: [...prevState.toasts, newToast],
        }));
        if (type === MessageBarType.success && this.container) {
            this.container.addEventListener("click", this.handleUserInteraction);
        }
    };

    private handleUserInteraction = (event: MouseEvent) => {
        this.removeSuccessToast();
        this.onToastDismissed();
        if (this.container) {
            this.container.removeEventListener("click", this.handleUserInteraction);
        }
    };

    private removeToast = (id: number) => {
        this.setState((prevState) => ({
            toasts: prevState.toasts.filter((toast) => toast.id !== id),
        }));
    };

    private showMultipleToasts = (toasts: { message: string; type: MessageBarType }[]) => {
        toasts.forEach((toast, index) => {
            setTimeout(() => {
                this.addToast(toast.message, toast.type);
            }, index * 200);
        });
    };

    private removeSuccessToast = () => {
        this.setState((prevState) => ({
            toasts: prevState.toasts.filter((toast) => toast.type !== MessageBarType.success),
        }));
    };

    getDropdownBoxShadow() {
        document.documentElement.style.setProperty(
            "--dropdown-box-shadow",
            this.state.theme === "default" ? customThemes.boxShadowLight : customThemes.boxShadowDark
        );
        document.documentElement.style.setProperty(
            "--custom-border",
            this.state.theme === "default" ? customThemes.borderLight : customThemes.borderDark
        );
    }

    showNoProjectsToast() {
        this.setState({ hasMyProjects: false });
    }

    hideNoProjectsToast() {
        this.setState({ hasMyProjects: true });
    }

    async onEulaRequired(isUpdate: boolean): Promise<Eula | undefined> {
        this.props.logger.info(`AppComponent.onEulaRequired called with isUpdate:${isUpdate}`);
        return new Promise((resolve) => {
            this.setState({ showEula: true, isEulaUpdate: isUpdate, onEulaUserResponse: resolve });
        });
    }

    handleLogoutEvent = async (): Promise<void> => {
        await this.onUserLogOut();
    };

    closeOrOpenFileTransferPanel(shouldShow: boolean) {
        this.setState({ shouldShowFileTransferPanel: shouldShow });
    }

    async onUserLogOut(): Promise<void> {
        this.props.logger.info("User signed out of add-in");
        this.props.authenticator.logoutFromApp();
        this.setState({ isLoggedIn: false, shouldShowFileTransferPanel: false });
        this.onToastDismissed();
    }

    async onApiLogIn(): Promise<void> {
        this.props.logger.info("User signed into add-in");
        await this.props.configurationsApiService.getAuthenticatedConfigurations();
        const shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache = this.props.configurationsApiService.shouldTeachingBubbleBeShown(
            TeachingBubblesTypes.fileMultipleEmail
        );
        const showFilesTransferTeachingBubble = this.props.configurationsApiService.shouldTeachingBubbleBeShown(
            TeachingBubblesTypes.filesTransfer
        );
        this.getFeatureSupport();
        this.setState({
            isLoggedIn: true,
            shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache,
            showFilesTransferTeachingBubble,
        });
    }

    onSettingsChanged(): void {
        const deleteAfterFiling = this.props.officeRoamingSettings.getDeleteEmailAfterFiling();
        const fileConversation = this.props.officeRoamingSettings.getFileEmailConversation();
        const showAllGlobalProjects = this.props.officeRoamingSettings.getShowAllGlobalProjects();
        this.setState({
            deleteEmailAfterFilingSetting: deleteAfterFiling,
            fileEmailConversationSetting: fileConversation,
            theme: localStorage.getItem("theme") ?? "default",
            showAllGlobalProjectsSetting: showAllGlobalProjects,
        });
    }

    async onAuthenticationError(errorMessage: string): Promise<void> {
        await this.showToast(this.props.translate(errorMessage) as string, MessageBarType.severeWarning);
    }

    private async itemChanged(_eventArgs: any): Promise<void> {
        // Used to trap email selection changes if the Task Pane is pinned
        this.props.logger.info("Selected mail item changed");
        const mailboxItem = this.props.officeWrapper.currentContextItem;
        if (_eventArgs.type !== "olkItemSelectedChanged" || !mailboxItem) {
            this.setState({ mailboxItem: null });
            return;
        }

        this.setState({ mailboxItem });
        this.onToastDismissed();
    }

    private async handleEulaResponse(eula: Eula | undefined): Promise<void> {
        if (this.state.onEulaUserResponse) {
            this.state.onEulaUserResponse(eula);
        }
        this.setState({ showEula: false, isEulaUpdate: false, onEulaUserResponse: undefined });
    }

    private async showToast(message: string | null, type: MessageBarType): Promise<void> {
        if (!message) {
            this.onToastDismissed();
            return;
        }
        this.setState({ toastMessage: message, toastType: type });
        if (type === MessageBarType.success && this.container) {
            this.container.addEventListener("click", this.handleUserInteraction);
        }
        this.props.officeNotificationService.showInfoToast(this.state.mailboxItem, this.infoToastKey, message);
    }

    private onToastDismissed(): void {
        this.setState({ toastMessage: null, toastType: null });
        this.props.officeNotificationService.dismissInfoToast(this.state.mailboxItem, this.infoToastKey);
    }

    private setNavigationPage(page: AppPage): void {
        this.setState({ navigationPage: page });
    }

    private getFeatureSupport(): void {
        this.setState({
            isEulaSupported: this.props.configurationsApiService.isEulaSupported(),
            isRfiSupported: this.props.configurationsApiService.isRfiSupported(),
            isActionItemsSupported: this.props.configurationsApiService.isActionItemsSupported(),
            isSubmittalsSupported: this.props.configurationsApiService.isSubmittalsSupported(),
            // what is being returned from the configurationsApiService for isFileTransferAndEditorSupported is the check of whether NL version is greater than 1.2.21 or not - the returned value is a boolean
            isFileTransferAndEditorSupported:
                this.props.configurationsApiService.isFileTransferAndEditorSupported() &&
                this.props.configurationsApiService.isFileTransferAndEditorSupportedBasedOnNLVersion(), // update this when ready to remove feature toggle
            isFilePathInAttachmentSupported: this.props.configurationsApiService.isFilePathSupportedInAttachments(),
            isForwardSubmittalSupported:
                this.props.configurationsApiService.isForwardSubmittalSupported() &&
                this.props.configurationsApiService.isForwardSubmittalAndRfiSupportedBasedOnNLVersion(),
            isForwardRfiSupported:
                this.props.configurationsApiService.isForwardRfiSupported() &&
                this.props.configurationsApiService.isForwardSubmittalAndRfiSupportedBasedOnNLVersion(),
        });
    }

    private async dismissMultipleEmailsTeachingBubble(): Promise<void> {
        try {
            this.setState({ shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache: false });
            this.props.configurationsApiService.updateCompletedTeachingBubblesCollection(
                TeachingBubblesTypes.fileMultipleEmail
            );
            await this.props.configurationsApiService.updateCompletedTeachingBubblesSteps([
                TeachingBubblesTypes.fileMultipleEmail,
            ]);
        } catch (error) {
            this.props.logger.error("Multiple Emails: Error updating teaching bubbles.", error);
        }
    }

    private async dismissFileTransferTeachingBubble(): Promise<void> {
        try {
            this.setState({ showFilesTransferTeachingBubble: false });
            this.props.configurationsApiService.updateCompletedTeachingBubblesCollection(
                TeachingBubblesTypes.filesTransfer
            );
            await this.props.configurationsApiService.updateCompletedTeachingBubblesSteps([
                TeachingBubblesTypes.filesTransfer,
            ]);
        } catch (error) {
            this.props.logger.error("File Transfer: Error updating teaching bubbles.", error);
        }
    }

    private showFileTransferButton(): void {
        this.setState({ shouldShowFileTransferButton: false });
    }

    private dismissRfiTeachingBubble = async () => {
        this.setState({ showForwardRfiTeachingBubble: false });
        await this.props.configurationsApiService.updateCompletedTeachingBubblesSteps([
            TeachingBubblesTypes.forwardReviewRfi,
        ]);
    };

    private dismissSubmittalTeachingBubble = async () => {
        this.setState({ showForwardSubmittalTeachingBubble: false });
        await this.props.configurationsApiService.updateCompletedTeachingBubblesSteps([
            TeachingBubblesTypes.forwardReviewSubmittal,
        ]);
    };

    render(): JSX.Element {
        const getCustomTheme = getTheme();
        return (
            <ThemeProvider theme={this.state.theme === "default" ? loadTheme(lightTheme) : loadTheme(darkTheme)}>
                <div className="newforma-appMain" data-theme={this.state.theme}>
                    <AppHeaderComponent
                        loggedIn={this.state.isLoggedIn}
                        onLogOut={this.onUserLogOut.bind(this)}
                        analyticsManager={this.props.analyticsManager}
                        configService={this.props.configService}
                        officeRoamingSettings={this.props.officeRoamingSettings}
                        officeWrapper={this.props.officeWrapper}
                        authenticator={this.props.authenticator}
                        logger={this.props.logger}
                        customerSupportEmailService={this.props.customerSupportEmailService}
                        windowWrapper={this.props.windowWrapper}
                        onSettingsChanged={this.onSettingsChanged.bind(this)}
                        isEulaSupported={this.state.isEulaSupported}
                        mailboxItem={this.state.mailboxItem}
                        smartFilingManager={this.props.smartFilingManager}
                        projectsService={this.props.projectsService}
                        emailApiService={this.props.emailApiService}
                        onExpiredSession={this.onUserLogOut.bind(this)}
                        sendAndFileHelpers={this.props.sendAndFileHelpers}
                        onShowToast={this.showToast.bind(this)}
                        onDismissToast={this.onToastDismissed.bind(this)}
                        formValidationHelpers={this.props.formValidationHelpers}
                        nrnServiceWrapper={this.props.nrnServiceWrapper}
                        msGraphApiService={this.props.msGraphApiService}
                        configurationService={this.props.configurationsApiService}
                        submittalsApiService={this.props.submittalsApiService}
                        storageWrapper={this.props.storageWrapper}
                        theme={this.state.theme}
                        dateHelpers={this.props.dateHelpers}
                        rfiApiService={this.props.rfiApiService}
                        invalidateCacheService={this.props.invalidateCacheService}
                        fileTransferApiService={this.props.fileTransferApiService}
                        showFilesTransferTeachingBubble={this.state.showFilesTransferTeachingBubble}
                        shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache={
                            this.state.shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache
                        }
                        onDismissTeachingBubble={this.dismissFileTransferTeachingBubble.bind(this)}
                        outlookApiService={this.props.outlookApiService}
                        showFileTransferButton={this.state.shouldShowFileTransferButton}
                        isFileTransferAndEditorSupported={this.state.isFileTransferAndEditorSupported}
                        isFilePathInAttachmentsSupported={this.state.isFilePathInAttachmentSupported}
                        toastMessageFromParent={this.state.toastMessage}
                        hideNoProjectsToast={this.hideNoProjectsToast.bind(this)}
                        shouldShowFileTransferPanel={this.closeOrOpenFileTransferPanel.bind(this)}
                        shouldShowFileTransferView={this.state.shouldShowFileTransferPanel}
                    />
                    {this.state.showEula ? (
                        <EulaComponent
                            onEulaResponse={this.handleEulaResponse.bind(this)}
                            eulaService={this.props.eulaApiService}
                            logger={this.props.logger}
                            analyticsManager={this.props.analyticsManager}
                            isEulaPreviouslyAccepted={this.state.isEulaUpdate}
                            theme={this.state.theme}
                        />
                    ) : null}
                    <HashRouter>
                        {this.state.isLoggedIn ? (
                            <div id="header" key="header" className="newforma-flexHeader">
                                <NavigationHeaderComponent
                                    defaultSelectedKey={this.state.navigationPage}
                                    onNavigationChange={this.setNavigationPage.bind(this)}
                                    isLoggedIn={this.state.isLoggedIn}
                                    isRfiSupported={this.state.isRfiSupported}
                                    isSubmittalsSupported={this.state.isSubmittalsSupported}
                                    isActionItemsSupported={this.state.isActionItemsSupported}
                                    location={this.state.locationPath}
                                    theme={this.state.theme}
                                    hideNoProjectsToast={this.hideNoProjectsToast.bind(this)}
                                />
                            </div>
                        ) : null}
                        {this.state.toastMessage && this.state.toastType !== null ? (
                            <div className="newforma-messageBarContainer newforma-messageBar-AppComponent">
                                <MessageBar
                                    className={getClassForToast(this.state.toastType, this.state.toastMessage.length)}
                                    messageBarType={this.state.toastType}
                                    styles={{
                                        icon: {
                                            color:
                                                this.state.theme !== "default"
                                                    ? getCustomTheme.palette.black
                                                    : undefined,
                                        },
                                    }}
                                    isMultiline={false}
                                    onDismiss={this.onToastDismissed.bind(this)}
                                >
                                    {this.state.toastMessage}
                                    {this.state.hasMyProjects === false ? (
                                        <>
                                            <br></br>
                                            <br></br>
                                            {this.props.translate("SHARED.ERRORS.FOR_ADDITIONAL_HELP") as string}
                                            <Link
                                                href="https://projectcenter.help.newforma.com/learning/how-tos/customize_my_project_center/"
                                                target="_blank"
                                                underline
                                            >
                                                {this.props.translate("SHARED.ERRORS.CLICK_HERE") as string}
                                            </Link>
                                        </>
                                    ) : null}
                                </MessageBar>
                            </div>
                        ) : null}
                        <div className="toast-wrapper">
                            {this.state.toasts.map((toast) => (
                                <div
                                    key={toast.id}
                                    className="newforma-multi-messageBarContainer newforma-messageBar-AppComponent"
                                >
                                    <MessageBar
                                        className={getClassForToast(toast.type, toast.message.length)}
                                        messageBarType={toast.type}
                                        styles={{
                                            icon: {
                                                color:
                                                    this.state.theme !== "default"
                                                        ? getCustomTheme.palette.black
                                                        : undefined,
                                            },
                                        }}
                                        isMultiline={false}
                                        onDismiss={() => this.removeToast(toast.id)}
                                    >
                                        {toast.message}
                                        {!this.state.hasMyProjects ? (
                                            <>
                                                <br></br>
                                                <br></br>
                                                {this.props.translate("SHARED.ERRORS.FOR_ADDITIONAL_HELP") as string}
                                                <Link
                                                    href="https://projectcenter.help.newforma.com/learning/how-tos/customize_my_project_center/"
                                                    target="_blank"
                                                    underline
                                                >
                                                    {this.props.translate("SHARED.ERRORS.CLICK_HERE") as string}
                                                </Link>
                                            </>
                                        ) : null}
                                    </MessageBar>
                                </div>
                            ))}
                        </div>
                        <Switch>
                            <Route exact path="/login">
                                <LoginComponent
                                    isLoggedIn={this.state.isLoggedIn}
                                    authenticator={this.props.authenticator}
                                    onApiLogin={this.onApiLogIn.bind(this)}
                                    redirectUrl={this.state.entryPointUrl}
                                    apiClient={this.props.unauthenticatedApiClient}
                                    officeWrapper={this.props.officeWrapper}
                                    logger={this.props.logger}
                                    eulaService={this.props.eulaApiService}
                                    onEulaDecline={this.onUserLogOut.bind(this)}
                                    analyticsManager={this.props.analyticsManager}
                                    isEulaSupported={this.state.isEulaSupported}
                                    theme={this.state.theme}
                                />
                            </Route>
                            <RedirectComponent key={1} isLoggedIn={this.state.isLoggedIn}>
                                <Route exact path={["/", "/filemultipleemail", "/fileemail"]}>
                                    <FileMultipleEmailComponent
                                        smartFilingManager={this.props.smartFilingManager}
                                        logger={this.props.logger}
                                        mailboxItem={this.state.mailboxItem}
                                        emailApiService={this.props.emailApiService}
                                        onSetNavigationPage={this.setNavigationPage.bind(this)}
                                        onShowToast={this.showToast.bind(this)}
                                        onExpiredSession={this.onUserLogOut.bind(this)}
                                        deleteEmailAfterFiling={this.state.deleteEmailAfterFilingSetting}
                                        msGraphApiService={this.props.msGraphApiService}
                                        configurationsApiService={this.props.configurationsApiService}
                                        officeNotificationService={this.props.officeNotificationService}
                                        analyticsManager={this.props.analyticsManager}
                                        projectsService={this.props.projectsService}
                                        nrnServiceWrapper={this.props.nrnServiceWrapper}
                                        authApiService={this.props.authApiService}
                                        shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache={
                                            this.state.shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache
                                        }
                                        theme={this.state.theme}
                                        invalidateCacheService={this.props.invalidateCacheService}
                                        messenger={this.props.messenger}
                                        officeRoamingSettings={this.props.officeRoamingSettings}
                                        onDismissTeachingBubble={this.dismissMultipleEmailsTeachingBubble.bind(this)}
                                        showNoProjectsToast={this.showNoProjectsToast.bind(this)}
                                        hideNoProjectsToast={this.hideNoProjectsToast.bind(this)}
                                    />
                                </Route>
                                <Route exact path="/sendandfile">
                                    <SendAndFileComponent
                                        officeRoamingSettings={this.props.officeRoamingSettings}
                                        smartFilingManager={this.props.smartFilingManager}
                                        onExpiredSession={this.onUserLogOut.bind(this)}
                                        officeWrapper={this.props.officeWrapper}
                                        officeNotificationService={this.props.officeNotificationService}
                                        analyticsManager={this.props.analyticsManager}
                                        logger={this.props.logger}
                                        // projectsService={this.props.projectsService}
                                        sendAndFileHelpers={this.props.sendAndFileHelpers}
                                        onSetNavigationPage={this.setNavigationPage.bind(this)}
                                        onShowToast={this.showToast.bind(this)}
                                        mailboxItem={this.state.mailboxItem}
                                        emailApiService={this.props.emailApiService}
                                        onComposeEmail={this.showFileTransferButton.bind(this)}
                                    />
                                </Route>
                                <Route exact path="/actionitem">
                                    <ActionItemComponent
                                        officeWrapper={this.props.officeWrapper}
                                        analyticsManager={this.props.analyticsManager}
                                        actionItemApiService={this.props.actionItemApiService}
                                        onExpiredSession={this.onUserLogOut.bind(this)}
                                        formValidationHelpers={this.props.formValidationHelpers}
                                        logger={this.props.logger}
                                        projectsService={this.props.projectsService}
                                        configurationService={this.props.configurationsApiService}
                                        smartFilingManager={this.props.smartFilingManager}
                                        onSetNavigationPage={this.setNavigationPage.bind(this)}
                                        onShowToast={this.showToast.bind(this)}
                                        mailboxItem={this.state.mailboxItem}
                                        msGraphApiService={this.props.msGraphApiService}
                                        nrnServiceWrapper={this.props.nrnServiceWrapper}
                                        theme={this.state.theme}
                                        invalidateCacheService={this.props.invalidateCacheService}
                                        isFilePathInAttachmentsSupported={this.state.isFilePathInAttachmentSupported}
                                        configService={this.props.configService}
                                        isFileTransferAndEditorSupported={this.state.isFileTransferAndEditorSupported}
                                    />
                                </Route>
                                <Route exact path="/submittal">
                                    <SubmittalComponent
                                        logger={this.props.logger}
                                        officeWrapper={this.props.officeWrapper}
                                        configurationService={this.props.configurationsApiService}
                                        formValidationHelpers={this.props.formValidationHelpers}
                                        projectsService={this.props.projectsService}
                                        onExpiredSession={this.onUserLogOut.bind(this)}
                                        dateHelpers={this.props.dateHelpers}
                                        analyticsManager={this.props.analyticsManager}
                                        submittalsApiService={this.props.submittalsApiService}
                                        smartFilingManager={this.props.smartFilingManager}
                                        onSetNavigationPage={this.setNavigationPage.bind(this)}
                                        onShowToast={this.showToast.bind(this)}
                                        showMultipleToasts={this.showMultipleToasts.bind(this)}
                                        mailboxItem={this.state.mailboxItem}
                                        nrnServiceWrapper={this.props.nrnServiceWrapper}
                                        msGraphApiService={this.props.msGraphApiService}
                                        theme={this.state.theme}
                                        invalidateCacheService={this.props.invalidateCacheService}
                                        isFilePathInAttachmentsSupported={this.state.isFilePathInAttachmentSupported}
                                        isFileTransferAndEditorSupported={this.state.isFileTransferAndEditorSupported}
                                        isForwardSubmittalSupported={this.state.isForwardSubmittalSupported}
                                        configService={this.props.configService}
                                        showForwardSubmittalTeachingBubble={
                                            this.state.showForwardSubmittalTeachingBubble
                                        }
                                        onDismissTeachingBubble={this.dismissSubmittalTeachingBubble}
                                    />
                                </Route>
                                <Route exact path="/submittalreviewresponse">
                                    <SubmittalReviewResponseComponent
                                        logger={this.props.logger}
                                        onExpiredSession={this.onUserLogOut.bind(this)}
                                        onSetNavigationPage={this.setNavigationPage.bind(this)}
                                        onShowToast={this.showToast.bind(this)}
                                        smartFilingManager={this.props.smartFilingManager}
                                        officeWrapper={this.props.officeWrapper}
                                        formValidationHelpers={this.props.formValidationHelpers}
                                        submittalsApiService={this.props.submittalsApiService}
                                        projectsService={this.props.projectsService}
                                        mailboxItem={this.state.mailboxItem}
                                        analyticsManager={this.props.analyticsManager}
                                        nrnServiceWrapper={this.props.nrnServiceWrapper}
                                        msGraphApiService={this.props.msGraphApiService}
                                        theme={this.state.theme}
                                        invalidateCacheService={this.props.invalidateCacheService}
                                        isFilePathInAttachmentsSupported={this.state.isFilePathInAttachmentSupported}
                                        isFileTransferAndEditorSupported={this.state.isFileTransferAndEditorSupported}
                                        configService={this.props.configService}
                                    />
                                </Route>
                                <Route exact path="/rfi">
                                    <RfiComponent
                                        configurationsApiService={this.props.configurationsApiService}
                                        smartFilingManager={this.props.smartFilingManager}
                                        logger={this.props.logger}
                                        onExpiredSession={this.onUserLogOut.bind(this)}
                                        projectsService={this.props.projectsService}
                                        formValidationHelpers={this.props.formValidationHelpers}
                                        officeWrapper={this.props.officeWrapper}
                                        dateHelpers={this.props.dateHelpers}
                                        rfiApiService={this.props.rfiApiService}
                                        onShowToast={this.showToast.bind(this)}
                                        onSetNavigationPage={this.setNavigationPage.bind(this)}
                                        mailboxItem={this.state.mailboxItem}
                                        analyticsManager={this.props.analyticsManager}
                                        nrnServiceWrapper={this.props.nrnServiceWrapper}
                                        msGraphApiService={this.props.msGraphApiService}
                                        theme={this.state.theme}
                                        invalidateCacheService={this.props.invalidateCacheService}
                                        isFilePathInAttachmentsSupported={this.state.isFilePathInAttachmentSupported}
                                        isFileTransferAndEditorSupported={this.state.isFileTransferAndEditorSupported}
                                        configService={this.props.configService}
                                        isForwardRfiSupported={this.state.isForwardRfiSupported}
                                        showForwardRfiTeachingBubble={this.state.showForwardRfiTeachingBubble}
                                        onDismissTeachingBubble={this.dismissRfiTeachingBubble}
                                    />
                                </Route>
                                <Route exact path="/rfireviewresponse">
                                    <RfiReviewResponseComponent
                                        logger={this.props.logger}
                                        onExpiredSession={this.onUserLogOut.bind(this)}
                                        onSetNavigationPage={this.setNavigationPage.bind(this)}
                                        onShowToast={this.showToast.bind(this)}
                                        smartFilingManager={this.props.smartFilingManager}
                                        officeWrapper={this.props.officeWrapper}
                                        formValidationHelpers={this.props.formValidationHelpers}
                                        rfiApiService={this.props.rfiApiService}
                                        projectsService={this.props.projectsService}
                                        mailboxItem={this.state.mailboxItem}
                                        analyticsManager={this.props.analyticsManager}
                                        nrnServiceWrapper={this.props.nrnServiceWrapper}
                                        msGraphApiService={this.props.msGraphApiService}
                                        dateHelpers={this.props.dateHelpers}
                                        theme={this.state.theme}
                                        invalidateCacheService={this.props.invalidateCacheService}
                                        isFilePathInAttachmentsSupported={this.state.isFilePathInAttachmentSupported}
                                        isFileTransferAndEditorSupported={this.state.isFileTransferAndEditorSupported}
                                        configService={this.props.configService}
                                    />
                                </Route>
                            </RedirectComponent>
                        </Switch>
                    </HashRouter>
                </div>
            </ThemeProvider>
        );
    }
}

export default withLocalize(AppComponent);
