import { NewformaApiClient } from "./NewformaApiClient";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { Log, Logger } from "../Logger";
import { LogSubmittalRequest } from "../../models/workflow/submittal/LogSubmittalRequest";
import { Request } from "aws-sign-web";
import { ApiRequestErrorLevel, ApiRequestErrorWithMessage } from "../../models/ApiRequestErrorWithMessage";
import { LogSubmittalResponse } from "../../models/workflow/submittal/LogSubmittalResponse";
import { LogSubmittalObjectResponse } from "../../models/workflow/submittal/LogSubmittalAndForwardResponse";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { OutlookApiService } from "../OutlookApiService";
import { FileUploadApiService } from "./FileUploadApiService";
import { AttachmentDetailsMetadata } from "../../models/AttachmentDetailsMetadata";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../AnalyticsManager";
import v4 = require("uuid/v4");
import { SubmittalReviewResponseBodyParams } from "../../models/workflow/submittal/SubmittalWorkflowActions";
import { WorkflowActionResponse } from "../../models/workflow/WorkflowActionResponse";
import { SubmittalDetailsResponse } from "../../models/workflow/submittal/SubmittalDetailsResponse";
import { ProjectKeywordsResponse } from "../../models/ProjectKeywordsResponse";
import { Submittal, SubmittalsResponse } from "../../models/workflow/submittal/SubmittalsResponse";
import { WorkflowActionType } from "../../models/workflow/WorkflowActionType";
import { EmailApiService } from "./EmailApiService";
import { NrnServiceWrapper } from "../NrnServiceWrapper";
import { Keyword } from "../../models/Keyword";
import { PostEmailFilingResponse } from "../../models/PostEmailFilingResponse";
import { AttachmentItem } from "../../models/shared/AttachmentList";
import { mapAttachmentsMetadataToAttachment } from "../../helpers/SendAndFile/AttachmentHelpers";
import { LogForwardSubmittalRequest } from "../../models/workflow/submittal/ForwardSubmittalRequest";
import { LogForwardSubmittalResponse } from "../../models/workflow/submittal/ForwardSubmittalResponse";
import { delay } from "../../helpers/UtilityHelper";

export enum SubmittalStatus {
    Closed = "closed",
    Forwarded = "forwarded",
    Open = "open",
}

export class SubmittalsApiService {
    private componentName: string = `${this.constructor.name}.`;
    private _urlRoot: string = "";
    private maxRetries = 3;
    private retryDelay = 5000; // 5 seconds
    constructor(
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private outlookApiService: OutlookApiService,
        private fileUploadApiService: FileUploadApiService,
        private analyticsManager: AnalyticsManager,
        private emailApiService: EmailApiService,
        private nrnServiceWrapper: NrnServiceWrapper
    ) {}

    get urlRoot() {
        if (this._urlRoot === "") {
            this._urlRoot = this.newformaApiClient.getHostNameWithProtocol();
        }
        return this._urlRoot;
    }

    getVia(projectNrn: string): Keyword | undefined {
        if (!this.nrnServiceWrapper.isCloudProject(projectNrn)) {
            return { name: "Email", type: "transferMethod" };
        }
        return undefined;
    }

    async logEmailAsSubmittal(
        projectNrn: string,
        messageNrn: string,
        submittal: LogSubmittalRequest,
        attachments: AttachmentItem[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        isLastModifiedDateSupported: boolean
    ): Promise<LogSubmittalObjectResponse> {
        Log.info(`${this.componentName} logEmailAsSubmittal`);
        let attachmentsDetails: AttachmentDetailsMetadata[] = [];

        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            submittal.attachments = mapAttachmentsMetadataToAttachment(attachmentsDetails, isLastModifiedDateSupported);
        }

        let response: LogSubmittalResponse;

        try {
            response = await this.logSubmittal(submittal, projectNrn);
        } catch (error) {
            Log.error(`${this.componentName} Logging a submittal ${JSON.stringify(error)}`);

            const handledErrors: { [index: string]: string } = {
                403: "SUBMITTALS.UNAUTHORIZED_ERROR",
                409: "SUBMITTALS.CREATE_FAILED_CONFLICT",
                500: "SUBMITTALS.UNEXPECTED_ERROR",
            };
            // if the error code doesn't match the above list, or any of its parent objects are not defined, it will map to 500
            const errorCode = (error as any).response?.status || 500;
            const messageToDisplay = handledErrors[errorCode] ?? handledErrors[500];
            if (messageToDisplay) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    messageToDisplay,
                    errorCode
                );
            }

            throw error;
        }

        if (attachments.length > 0) {
            const batchId = v4();
            const result = await this.fileUploadApiService.uploadWorkflowAttachments(
                attachmentsDetails,
                response.uploadFolderNrn,
                fileUploadCallback,
                batchId
            );
            fileUploadCallback(false, result.failedFileIds);
            if (result.failedFileIds.length > 0) {
                throw new ApiRequestErrorWithMessage(
                    "one or more files failed to upload",
                    ApiRequestErrorLevel.ERROR,
                    "SUBMITTALS.CREATE_FAILED_ATTACHMENTS_UPLOAD",
                    400
                );
            }
        }
        // if there are no attachments, then call forward submittal endpoint

        if (attachments.length === 0 && submittal.forwardSubmittalData) {
            await this.logEmailAsForwardSubmittal(
                submittal.forwardSubmittalData,
                projectNrn,
                response.nrn,
                attachments,
                messageNrn,
                isLastModifiedDateSupported
            );
        }
        // why is this needed?
        const filingProjectNrn = this.nrnServiceWrapper.isCloudProject(projectNrn) ? projectNrn : response.nrn;
        await this.fileEmailToProject(filingProjectNrn, messageNrn);
        return { submittalResponse: response };
    }

    async logEmailAsForwardSubmittal(
        forwardSubmittal: LogForwardSubmittalRequest,
        projectNrn: string,
        submittalNrn: string,
        attachments: AttachmentItem[],
        messageNrn: string,
        isLastModifiedDateSupported: boolean
    ): Promise<LogForwardSubmittalResponse> {
        Log.info(`${this.componentName} logEmailAsForwardSubmittal`);
        let attachmentsDetails;
        let forwardSuccess = false;
        let attempt = 0;

        const forwardError: string | null = null;
        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            forwardSubmittal.attachments = mapAttachmentsMetadataToAttachment(
                attachmentsDetails,
                isLastModifiedDateSupported
            );
        }
        while (attempt < this.maxRetries && !forwardSuccess) {
            try {
                attempt++;
                Log.info(`${this.componentName} lfs Try ${attempt}`);

                await this.logForwardSubmittal(forwardSubmittal, projectNrn, submittalNrn);
                forwardSuccess = true;
            } catch (error) {
                Log.error(`${this.componentName} - Logging a forwarded submittal`, error);
                if (attempt >= this.maxRetries) {
                    forwardSuccess = false;

                    const handledErrors: { [index: string]: string } = {
                        403: "SUBMITTALS.UNAUTHORIZED_ERROR",
                        409: "SUBMITTALS.CREATE_FAILED_CONFLICT",
                        500: "SUBMITTALS.UNEXPECTED_ERROR",
                    };

                    const errorCode = (error as any).response?.status || 500;
                    const errorMessage = handledErrors[errorCode] ?? handledErrors[500];

                    throw new Error(errorMessage);
                }
                await delay(this.retryDelay);
            }
        }

        return { forwardSuccess, forwardError };
    }

    async logEmailAsSubmittalReviewerResponse(
        projectNrn: string,
        messageNrn: string,
        submittalNrn: string,
        params: SubmittalReviewResponseBodyParams,
        attachments: AttachmentItem[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        isLastModifiedDateSupported: boolean
    ): Promise<WorkflowActionResponse> {
        this.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserActions,
            AnalyticsActionType.FileEmailAsSubmittalReviewResponse
        );

        let attachmentsDetails: AttachmentDetailsMetadata[] = [];
        Log.info(`${this.componentName} logEmailAsSubmittalReviewerResponse`);
        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            params.attachments = mapAttachmentsMetadataToAttachment(attachmentsDetails, isLastModifiedDateSupported);
        }

        let response: WorkflowActionResponse;

        try {
            response = await this.logSubmittalReviewerResponse(params, projectNrn, submittalNrn);
        } catch (error) {
            Log.error(`${this.componentName} Logging a Submittal Reviewer Response`, error);

            const handledErrors: { [index: string]: string } = {
                403: "SUBMITTALS.RESPONSE.UNAUTHORIZED_ERROR",
                409: "SUBMITTALS.RESPONSE.FAILED_CONFLICT",
                500: "SUBMITTALS.RESPONSE.FAILED_GENERIC",
            };
            const errorCode = (error as any).response?.status || 500;
            const messageToDisplay = handledErrors[errorCode] ?? handledErrors[500];
            if (messageToDisplay) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    messageToDisplay,
                    errorCode
                );
            }

            throw error;
        }

        if (attachments.length > 0) {
            const batchId = v4();
            const result = await this.fileUploadApiService.uploadWorkflowAttachments(
                attachmentsDetails,
                response.uploadFolderNrn,
                fileUploadCallback,
                batchId
            );
            fileUploadCallback(false, result.failedFileIds);
            if (result.failedFileIds.length > 0) {
                throw new ApiRequestErrorWithMessage(
                    "one or more files failed to upload",
                    ApiRequestErrorLevel.ERROR,
                    "SUBMITTALS.RESPONSE.FAILED_ATTACHMENTS_UPLOAD",
                    400
                );
            }
        }
        // is this even required?
        const filingNrn = this.nrnServiceWrapper.isCloudProject(projectNrn) ? projectNrn : submittalNrn;
        await this.fileEmailToProject(filingNrn, messageNrn);

        return response;
    }

    async getSubmittalDetails(submittalNrn: string, projectNrn: string): Promise<SubmittalDetailsResponse> {
        Log.info(`${this.componentName} getSubmittalDetails`);

        const encProjectNrn = encodeURIComponent(projectNrn);
        const encSubmittalNrn = encodeURIComponent(submittalNrn);
        const url = `${this.urlRoot}/v1/projects/${encProjectNrn}/submittals/${encSubmittalNrn}`;
        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    async getWorkflowActionKeywords(
        projectNrn: string,
        actionType: WorkflowActionType
    ): Promise<ProjectKeywordsResponse> {
        Log.info(`${this.componentName} getWorkflowActionKeywords`);
        const encProjectNrn = encodeURIComponent(projectNrn);
        // actiontype should be lowercased, to be consistent?
        const url = `${this.urlRoot}/v1/projects/${encProjectNrn}/submittals/workflowactions/${actionType}/keywords`;
        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    async getForwardedSubmittals(projectNrn: string): Promise<Submittal[]> {
        Log.info(`${this.componentName} getForwardedSubmittals`);

        const isCloudProject = this.nrnServiceWrapper.isCloudProject(projectNrn);
        const result = await this.getSubmittals(projectNrn, undefined, undefined);

        let offsetToken = result.paging?.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getSubmittals(projectNrn, undefined, offsetToken);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.paging?.offsetToken;
        }

        result.paging = undefined;

        let forwardedSubmittals = [];

        if (isCloudProject) {
            forwardedSubmittals = result.items.filter(
                (submittal) => submittal.status.type === SubmittalStatus.Forwarded
            );
        } else {
            forwardedSubmittals = result.items.filter(
                (submittal) =>
                    submittal.status.type === SubmittalStatus.Forwarded ||
                    submittal.status.type === SubmittalStatus.Open
            );
        }
        return forwardedSubmittals;
    }

    private logSubmittal(submittal: LogSubmittalRequest, projectNrn: string): Promise<LogSubmittalResponse> {
        Log.info(`${this.componentName} logSubmittal`);
        const encProjectNrn = encodeURIComponent(projectNrn);
        const options: Request = {
            url: `${this.urlRoot}/v1/projects/${encProjectNrn}/submittals/log`,
            method: "POST",
            body: JSON.stringify(submittal),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private logForwardSubmittal(
        submittal: LogForwardSubmittalRequest,
        projectNrn: string,
        submittalNrn: string
    ): Promise<any> {
        Log.info(`${this.componentName} logForwardSubmittal`);
        const encProjectNrn = encodeURIComponent(projectNrn);
        const encSubmittalNrn = encodeURIComponent(submittalNrn);
        const url = `${
            this.urlRoot
        }/v1/projects/${encProjectNrn}/submittals/${encSubmittalNrn}/${WorkflowActionType.Forward.toLocaleLowerCase()}`;

        const options: Request = {
            url: url,
            method: "PUT",
            body: JSON.stringify(submittal),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.put(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private async logSubmittalReviewerResponse(
        reviewerResponse: SubmittalReviewResponseBodyParams,
        projectNrn: string,
        submittalNrn: string
    ): Promise<WorkflowActionResponse> {
        Log.info(`${this.componentName} logSubmittalReviewerResponse`);

        const encProjectNrn = encodeURIComponent(projectNrn);
        const encSubmittalNrn = encodeURIComponent(submittalNrn);
        const url = `${
            this.urlRoot
        }/v1/projects/${encProjectNrn}/submittals/${encSubmittalNrn}/${WorkflowActionType.ReviewResponse.toLocaleLowerCase()}`;

        console.log(`Url: ${url}`);

        const options: Request = {
            url: url,
            method: "PUT",
            body: JSON.stringify(reviewerResponse),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.put(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }
    lastMessageNrn: any = undefined;

    private async fileEmailToProject(projectNrn: string, messageNrn: string): Promise<PostEmailFilingResponse> {
        Log.info(`${this.componentName} fileEmailToProject`);

        if (messageNrn === this.lastMessageNrn) {
            Log.info(`${this.componentName}  Warning: ** email is already filed **`);
            return { emailLogIsSupported: true } as PostEmailFilingResponse;
        }
        this.lastMessageNrn = messageNrn;

        try {
            return await this.emailApiService.fileToProject(projectNrn, messageNrn);
        } catch (error) {
            Log.error(`${this.componentName} Logging a submittal - Filing email`, error);

            if (ExpiredSessionError.isInstanceOf(error)) {
                throw error;
            }

            throw new ApiRequestErrorWithMessage(
                (error as any).message,
                ApiRequestErrorLevel.ERROR,
                "SUBMITTALS.CREATE_FAILED_EMAIL_NOT_CREATED",
                (error as any).status
            );
        }
    }

    private async getSubmittals(
        projectNrn: string,
        filter: string = "",
        offsetToken: string = ""
    ): Promise<SubmittalsResponse> {
        Log.info(`${this.componentName} getSubmittals`);
        const maxPageSize = 50;
       
        const url = `${this.urlRoot}/v1/projects/${projectNrn}/submittals?status=${filter}&maxSize=${maxPageSize}&offsetToken=${offsetToken}`;

        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }
}
