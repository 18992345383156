// export let localLoggingEnabled = process?.env?.NODE_ENV !== "production";
export let localLoggingEnabled = false; // use this line instead of above line to turn off extra logging when running locally
let deltaLoggingEnabled = false;

//
try {
    // create an entry for dev to modify in the inspector application tab
    if (window.localStorage.deltaLoggingEnabled === undefined) {
        window.localStorage.deltaLoggingEnabled = "false";
    }

    deltaLoggingEnabled = window.localStorage.deltaLoggingEnabled === "true";
} catch {
    console.warn("localStorage is not enabled.");
}

export class Logger {
    private logs: string[] = [];
    private readonly maximumLogLength = 1000;
    private tmLast: number = new Date().getTime();
    enabled = false;

    constructor() {
        Log = this;
        this.enabled = localLoggingEnabled;
    }
    enable(value: boolean) {
        this.enabled = value;
    }

    info(message: string, object?: any): void {
        this.log("INFO", message, object);
        if (this.enabled) {
            console.log(`INFO: ${this.getTimeAndDelta()} ${message} ${object ?? ""}`);
        }
    }

    warning(message: string, object?: any): void {
        this.log("WARN", message, object);
        if (this.enabled) {
            console.warn(`WARN: ${this.getTimeAndDelta()} ${message} ${object ?? ""}`);
        }
    }

    error(message: string, object?: any): void {
        this.log("ERROR", message, object);
        if (this.enabled) {
            console.error(`ERROR: ${this.getTimeAndDelta()} ${message} ${object ?? ""}`);
        }
    }

    getLogs(): string[] {
        return this.logs;
    }

    private getTimeAndDelta(): string {
        if (!deltaLoggingEnabled) {
            return `[NPC] `;
        }

        const tm = new Date().getTime();

        const delta = tm - this.tmLast;
        this.tmLast = tm;

        return `[NPC ${this.getHMS()} diff ${String(delta).padStart(5, "0")} ms] `;
    }

    private getHMS(): string {
        const tm = new Date();
        return `${tm.toString().substring(16, 24)}.${String(tm.getMilliseconds()).padStart(3, "0")}`;
    }

    private getTimestamp(): string {
        return `[${new Date().toUTCString()}]`;
    }

    private log(prefix: string, message: string, object?: any): void {
        const completeMessage = `${this.getTimestamp()} ${prefix}: ${message} ${
            !object ? "" : JSON.stringify(object, Object.getOwnPropertyNames(object))
        }`;

        if (this.logs.length >= this.maximumLogLength) {
            this.logs.shift();
        }
        this.logs.push(completeMessage);
    }
}
let Log: Logger = new Logger();

export { Log };
