import { Icon, Separator, Stack } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Logger } from "../../../services/Logger";
import "./forwardSubmittal.less";
import FromAndToComponent from "../../shared/fromAndToComponent/FromAndToComponent";
import KeywordsDropdown from "../../shared/keywordsDropdown/KeywordsDropdown";
import { OfficeWrapper, MailboxItem } from "../../../services/OfficeWrapper";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import { IPersonaProps, ITag, MessageBarType, Label, TextField } from "office-ui-fabric-react";
import { DetailedKeyword as ProjectKeyword } from "../../../models/ProjectKeywordsResponse";
import { ProjectHelper } from "../../../helpers/ProjectHelper";
import { ExpiredSessionError } from "../../../models/ExpiredSessionError";
import { EmailRecipients } from "../../../models/shared/FileTransferEmailFieldsDetails";
import TranslatedDatePickerComponent from "../../shared/translatedDatePicker/TranslatedDatePickerComponent";
import { ElementVisibility } from "../../../models/shared/ElementVisibility";
import ReminderComponent from "../../shared/reminder/ReminderComponent";
import * as _ from "lodash";
import HTMLEditor from "../../shared/editor/Editor";
import { ConfigurationService } from "../../../services/ConfigurationService";

export interface ForwardSubmittalProps extends LocalizeContextProps {
    shouldShowSubmittal: () => void;
    theme: string;
    logger: Logger;
    officeWrapper: OfficeWrapper;
    formValidationHelpers: FormValidationHelpers;
    mailboxItem: MailboxItem | null;
    projectsService: IProjectsService;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    selectedProject: ITag | null;
    onExpiredSession: () => void;
    updatePurposes: (option: ProjectKeyword | null, purposes: ProjectKeyword[]) => void;
    updateLoadingState: (loadingPurposes: boolean) => void;
    priorSelectedPurpose: ProjectKeyword | null;
    priorSelectedPurposes: ProjectKeyword[];
    updateEmails: (emails: EmailRecipients) => void;
    priorEmails: EmailRecipients;
    updateForwardDueDate: (date: Date | undefined) => void;
    forwardDueDate: Date | undefined;
    updateRemarks: (text: string | undefined) => void;
    forwardRemarks: string | undefined;
    updateReminder: (date: number | undefined) => void;
    forwardReminder: number | undefined;
    updateClearValue: () => void;
    shouldClearValues: boolean;
    configService: ConfigurationService;
    onFormValidationChange: (isValid: boolean) => void;
    isFileTransferAndEditorSupported: boolean;
    isFiling: boolean;
    onEmailCheck?: (value: boolean) => void;
    onDateCheck?: (value: boolean) => void;
}

export interface ForwardSubmittalState {
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    shouldClearEmails: boolean;
    isClearForm: boolean;
    purposes: ProjectKeyword[];
    selectedPurpose: ProjectKeyword | null;
    isLoadingKeywords: boolean;
    date: Date | undefined;
    remarks: string | undefined;
    reminderDays: number | undefined;
    dueDate: Date | undefined;
    isDateInPast?: boolean;
    areToAndCCSame?: boolean;
}

class ForwardSubmittal extends React.Component<ForwardSubmittalProps, ForwardSubmittalState> {
    defaultState: ForwardSubmittalState = {
        from: [],
        to: [],
        cc: [],
        shouldClearEmails: false,
        isClearForm: false,
        purposes: [],
        selectedPurpose: null,
        date: undefined,
        isLoadingKeywords: false,
        remarks: "",
        reminderDays: undefined,
        dueDate: undefined,
        isDateInPast: false,
        areToAndCCSame: false,
    };

    reminderMaxValue = 365;

    constructor(props: ForwardSubmittalProps) {
        super(props);

        this.state = this.defaultState;
    }

    async componentDidMount() {
        this.props.logger.info("Forward submittal component mounted");
        document.querySelector(".newforma-submittalComponent")?.scrollTo({
            top: 0,
            behavior: "auto",
        });
        if (this.props.selectedProject && !this.props.priorSelectedPurpose) {
            await this.getKeywords(this.props.selectedProject.key, this.props.selectedProject.name);
        } else if (this.props.priorSelectedPurpose && this.props.priorSelectedPurposes) {
            this.setState({
                selectedPurpose: this.props.priorSelectedPurpose,
                purposes: this.props.priorSelectedPurposes,
            });
        }
        this.setEmailsState("to", this.props.priorEmails.to);
        this.setEmailsState("cc", this.props.priorEmails.cc);
        if (this.props.forwardDueDate) {
            this.setState({ date: this.props.forwardDueDate });
        }
        if (this.props.forwardRemarks) {
            this.setState({ remarks: this.props.forwardRemarks });
        }
        if (this.props.forwardReminder) {
            this.setState({ reminderDays: this.props.forwardReminder });
        }
        this.updateForwardButtonState();
    }

    async componentDidUpdate(prevProps: Readonly<ForwardSubmittalProps>, prevState: Readonly<ForwardSubmittalState>) {
        if (this.props.selectedProject && this.props.selectedProject !== prevProps.selectedProject) {
            await this.getKeywords(this.props.selectedProject.key, this.props.selectedProject.name);
        }
    }

    private onToChange(items?: IPersonaProps[]): void {
        this.setState(
            {
                to: items || [],
                shouldClearEmails: false,
            },
            () => {
                this.props.updateEmails({ to: this.state.to, cc: this.state.cc });
                this.updateForwardButtonState();
            }
        );
    }

    private onCcChange(people: IPersonaProps[]): void {
        this.setState(
            {
                cc: people || [],
                shouldClearEmails: false,
            },
            () => {
                this.props.updateEmails({ to: this.state.to, cc: this.state.cc });
                this.updateForwardButtonState();
            }
        );
    }

    private onCCAndToComparison(areToAndCCSame: boolean): void {
        this.setState({ areToAndCCSame });
        this.props.onEmailCheck?.(areToAndCCSame);
    }

    private onDateComparison(isDateInPast: boolean): void {
        this.setState({ isDateInPast });
        this.props.onDateCheck?.(isDateInPast);
    }

    private onFromChange(people: IPersonaProps[]): void {
        this.setState({ from: people });
    }

    private onPurposeSelectionChange(option: ProjectKeyword | null): void {
        this.setState({ selectedPurpose: option });
        this.props.updatePurposes(option, this.state.purposes);
        this.updateForwardButtonState;
    }

    private updateForwardButtonState = () => {
        const isFormValid = this.isFormValid();
        this.props.onFormValidationChange(isFormValid);
    };

    private isFormValid(): boolean {
        const { to, cc, selectedPurpose, date } = this.state;
        const isCcValid = _.isEmpty(cc) || this.props.formValidationHelpers.areAssigneesValid(cc, true);
        const isValid = !!(
            _.size(to) &&
            selectedPurpose !== null &&
            date !== undefined &&
            this.props.formValidationHelpers.areAssigneesValid(to, true) &&
            isCcValid
        );

        return isValid;
    }

    private async getKeywords(projectNrn: string, projectName: string): Promise<void> {
        this.props.logger.info(`Loading keywords in forward submittal for selected project: ${projectName}`);
        this.setState({ isLoadingKeywords: true });
        this.props.updateLoadingState(true);
        try {
            const purposesResponse = await this.props.projectsService.getProjectForwardSubmittalPurposesKeywords(
                projectNrn
            );

            const purposes = ProjectHelper.sortKeywords(purposesResponse);

            this.setState({
                purposes: purposes,
            });

            if (purposes && purposes.length > 0) {
                this.setState({ selectedPurpose: purposes[0] });
                this.props.updatePurposes(purposes[0], purposes);
            }
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.logger.error("ForwardSubmittalComponent Error loading keywords", error);

            this.clearKeywords();
            this.props.onShowToast(
                this.props.translate("SUBMITTALS.LOADING_KEYWORDS_FAILED") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingKeywords: false });
            this.props.updateLoadingState(false);
        }
    }

    private clearKeywords(): void {
        this.setState({
            purposes: [],
            selectedPurpose: null,
        });
    }

    private onBackButtonClicked(): void {
        this.props.shouldShowSubmittal();
        this.props.updateEmails({ to: this.state.to, cc: this.state.cc });
        this.props.updateRemarks(this.state.remarks);
        this.props.updateClearValue();
    }

    private onDateChange(date: Date | undefined): void {
        this.setState({ date: date }, this.updateForwardButtonState);
        this.props.updateForwardDueDate(date);
    }

    private onRemarksChange(newValue?: string): void {
        this.setState({ remarks: newValue || "" });
        this.props.updateRemarks(newValue);
    }

    private onOldEditorRemarksChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ remarks: newValue || "" });
        this.props.updateRemarks(newValue);
    }

    private onReminderChange(value: number | undefined): void {
        this.setState({ reminderDays: value });
        this.props.updateReminder(value);
    }

    private setEmailsState(key: keyof EmailRecipients, emails?: IPersonaProps[]): void {
        if (_.size(emails)) {
            this.setState((prevState) => ({
                ...prevState,
                [key]: emails || [],
            }));
        }
    }
    render(): JSX.Element {
        return (
            <div className="newforma-forwardSubmittalContainer margin-bottom">
                <div className="headerText">
                    <Stack className="headerText">
                        <Separator className="custom-separator-bg" alignContent="start">
                            <div className="headerText">
                                {this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.HEADER_TEXT")}
                            </div>
                        </Separator>
                    </Stack>
                </div>
                <div
                    className="newforma-forwardSubmittalBackContainer"
                    onClick={() => this.onBackButtonClicked()}
                    data-theme={this.props.theme}
                >
                    <Icon iconName="NavigateBack" className="backIcon" data-theme={this.props.theme} />
                    <span className="submittalText">
                        {this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.BACK_BUTTON")}
                    </span>
                </div>
                <TranslatedDatePickerComponent
                    date={this.props.forwardDueDate}
                    label={this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.DUE_BACK_DATE") as string}
                    disabled={this.props.isFiling}
                    onDateChange={this.onDateChange.bind(this)}
                    className="newforma-aiSpacing"
                    required={true}
                    clearDateButtonVisibility={ElementVisibility.None}
                    shouldValidateDateInPast={true}
                    onDateCheck={this.onDateComparison.bind(this)}
                />
                <ReminderComponent
                    onChange={this.onReminderChange.bind(this)}
                    className="newforma-formSpacing newforma-reminderFont"
                    max={this.reminderMaxValue}
                    disabled={false}
                    value={this.props.forwardReminder}
                    isFiling={this.props.isFiling}
                />
                <FromAndToComponent
                    className={"newforma-formSpacing"}
                    isFromRequired={false}
                    isToRequired={true}
                    isCcRequired={false}
                    includeCc={true}
                    officeWrapper={this.props.officeWrapper}
                    disabled={this.props.isFiling}
                    formValidationHelpers={this.props.formValidationHelpers}
                    mailboxItem={this.props.mailboxItem}
                    onFromChange={this.onFromChange.bind(this)}
                    onToChange={this.onToChange.bind(this)}
                    onCcChange={this.onCcChange.bind(this)}
                    logger={this.props.logger}
                    projectsService={this.props.projectsService}
                    onError={this.props.onShowToast}
                    project={this.props.selectedProject}
                    shouldHideFrom={true}
                    shouldClearEmails={this.state.shouldClearEmails || this.state.isClearForm}
                    EmailRecipients={this.props.priorEmails}
                    isTransferFailed={true}
                    isForwardInProgress={true}
                    onEmailCheck={this.onCCAndToComparison.bind(this)}
                />
                <KeywordsDropdown
                    className="newforma-forwardSubmittalPurposeDropdown"
                    id="purposePicker"
                    options={this.state.purposes}
                    label={this.props.translate("SUBMITTALS.PURPOSE_PICKER_LABEL") as string}
                    placeholder={this.props.translate("SUBMITTALS.PURPOSE_PICKER_PLACEHOLDER") as string}
                    disabled={!this.state.purposes.length || this.props.isFiling}
                    isLoading={this.state.isLoadingKeywords}
                    isProjectSelected={true}
                    required={true}
                    onSelectionChange={this.onPurposeSelectionChange.bind(this)}
                    selectFirstOption={false}
                    theme={this.props.theme}
                    selectedPurposeKey={this.state.selectedPurpose ? this.state.selectedPurpose.displayOrder : null}
                />
                <Label>{this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.REMARKS") as string}</Label>
                {this.props.isFileTransferAndEditorSupported ? (
                    <HTMLEditor
                        value={this.state.remarks}
                        onRemarksUpdate={this.onRemarksChange.bind(this)}
                        configService={this.props.configService}
                        isFiling={this.props.isFiling}
                    />
                ) : (
                    <TextField
                        className="newforma-submittalSpacing"
                        id="newforma-forwardSubmittalDescription"
                        multiline
                        resizable={true}
                        value={this.state.remarks}
                        onChange={this.onOldEditorRemarksChange.bind(this)}
                        disabled={this.props.isFiling}
                        rows={5}
                        maxLength={65000}
                    />
                )}
            </div>
        );
    }
}

export default withLocalize(ForwardSubmittal);
