import {
    Checkbox,
    TooltipHost,
    DirectionalHint,
    ITooltipProps,
    TooltipDelay,
    TeachingBubble,
    ITeachingBubbleStyles,
} from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import "./forwardCheckbox.less";
import { TeachingBubblesTypes } from "../../../models/TeachingBubblesTypes";

export interface ForwardCheckboxProps extends LocalizeContextProps {
    label: string;
    subtext: string;
    shouldForward: () => void;
    checked: boolean;
    isFiling: boolean;
    showDisableMessage?: boolean;
    theme: string;
    showTeachingBubble?: boolean;
    teachingBubbleType?: string;
    onDismissTeachingBubble: () => void;
}

interface ForwardCheckboxState {
    showBubble: boolean;
}

class ForwardCheckbox extends React.Component<ForwardCheckboxProps, ForwardCheckboxState> {
    private textColor: string;
    private backgroundColor: string;

    constructor(props: ForwardCheckboxProps) {
        super(props);
        this.state = {
            showBubble: false,
        };

        const isDefaultTheme = props.theme === "default";
        this.textColor = isDefaultTheme ? "white" : "black";
        this.backgroundColor = isDefaultTheme ? "black" : "white";
    }

    componentDidUpdate(prevProps: ForwardCheckboxProps) {
        if (this.props.showTeachingBubble) {
            this.scrollToElementAndCheckVisibility("forward-review-checkbox");
        }
    }

    scrollToElementAndCheckVisibility(elementId: string): void {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });

            const checkIfScrollEnded = () => {
                const boundingRect = element.getBoundingClientRect();
                if (
                    boundingRect.top >= 0 &&
                    boundingRect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                ) {
                    if (this.props.showTeachingBubble && !this.state.showBubble) {
                        this.setState({ showBubble: true });
                    }
                } else {
                    requestAnimationFrame(checkIfScrollEnded);
                }
            };

            requestAnimationFrame(checkIfScrollEnded);
        } else {
            if (!this.state.showBubble) {
                this.setState({ showBubble: true });
            }
        }
    }

    private getTeachingBubbleStyles = (): Partial<ITeachingBubbleStyles> => ({
        headline: {
            color: this.textColor,
            fontSize: "19px",
        },
        subText: {
            color: this.textColor,
        },
    });

    renderTooltipContent = () => (
        <div className="tooltip-content">
            {this.props.translate("SHARED.TOOLTIP.PROJECT_NOT_AVAILABLE_START")}{" "}
            <span className="tooltip-bold">{this.props.translate("SHARED.TOOLTIP.PROJECT_NOT_AVAILABLE_BOLD")}</span>{" "}
            {this.props.translate("SHARED.TOOLTIP.PROJECT_NOT_AVAILABLE_END")}
        </div>
    );

    handleDismissTeachingBubble = () => {
        this.setState({ showBubble: false });
        this.props.onDismissTeachingBubble?.();
    };

    render(): JSX.Element {
        const { label, subtext, shouldForward, checked, isFiling, showDisableMessage } = this.props;
        const { showBubble } = this.state;

        const tooltipProps: ITooltipProps = {
            onRenderContent: showDisableMessage ? this.renderTooltipContent : undefined,
            calloutProps: { className: "custom-tooltip-callout", gapSpace: 10 },
            delay: TooltipDelay.medium,
            directionalHint: DirectionalHint.topCenter,
        };

        return (
            <div className="newforma-checkboxContainer">
                {showBubble && <div className="page-overlay" aria-hidden="true" />}
                <TooltipHost
                    tooltipProps={tooltipProps}
                    styles={{ root: { display: "inline-block", position: "relative" } }}
                    key="tooltip-host"
                >
                    <div>
                        <Checkbox
                            id="forward-review-checkbox"
                            label={label}
                            onChange={shouldForward}
                            className={isFiling ? "checkboxFiling" : "checkbox"}
                            checked={checked}
                            disabled={isFiling}
                            key="forward-checkbox"
                        />

                        <div
                            className={
                                isFiling
                                    ? "newforma-subtextCheckboxContainerFiling"
                                    : "newforma-subtextCheckboxContainer"
                            }
                            key="forward-checkbox-subtext"
                        >
                            {subtext}
                        </div>
                    </div>
                </TooltipHost>

                {showBubble && (
                    <TeachingBubble
                        target="#forward-review-checkbox"
                        calloutProps={{
                            className: "custom-bubble-callout",
                            directionalHint: DirectionalHint.topCenter,
                            gapSpace: 10,
                            preventDismissOnScroll: true,
                        }}
                        headline={String(
                            this.props.teachingBubbleType === TeachingBubblesTypes.forwardReviewRfi
                                ? this.props.translate("TEACHING_BUBBLES_v1.FORWARD_RFI_BUBBLE.HEADLINE")
                                : this.props.translate("TEACHING_BUBBLES_v1.FORWARD_SUBMITTAL_BUBBLE.HEADLINE")
                        )}
                        styles={this.getTeachingBubbleStyles()}
                        onDismiss={this.handleDismissTeachingBubble}
                    >
                        <div>
                            <p key="teaching-bubble-paragraph">
                                {this.props.translate("TEACHING_BUBBLES_v1.COMMON.PARAGRAPH_START")}{" "}
                                <strong key="bubble-paragraph-middle">
                                    {this.props.teachingBubbleType === TeachingBubblesTypes.forwardReviewRfi
                                        ? this.props.translate(
                                              "TEACHING_BUBBLES_v1.FORWARD_RFI_BUBBLE.PARAGRAPH_MIDDLE"
                                          )
                                        : this.props.translate(
                                              "TEACHING_BUBBLES_v1.FORWARD_SUBMITTAL_BUBBLE.PARAGRAPH_MIDDLE"
                                          )}
                                </strong>{" "}
                                {this.props.translate("TEACHING_BUBBLES_v1.COMMON.PARAGRAPH_END")}
                            </p>
                            <button
                                className="custom-close-button"
                                style={{
                                    backgroundColor: this.textColor,
                                    color: this.backgroundColor,
                                }}
                                onClick={this.handleDismissTeachingBubble}
                                key="teaching-bubble-close-button"
                            >
                                {this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_3")}
                            </button>
                        </div>
                    </TeachingBubble>
                )}
            </div>
        );
    }
}

export default withLocalize(ForwardCheckbox);
